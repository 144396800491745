<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageheader :title="title" :title1="title1" />
                <!-- /add -->
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="update">
                            <div class="row">
                                <div class="col-lg-12 d-flex justify-content-center gap-3 align-items-center mb-5">
                                    <div style="width: 100px; height: 100px; background-size: cover; background-position: center; cursor: pointer;"
                                        :style="{
                                            'background-image': currentData.gambar1 ? 'url(' + currentData.gambar1 + ')' : 'url(' + require('@/assets/img/img_default_2.png') + ')',
                                            'border': currentData.gambar1 ? '1px solid #043249' : 'none'
                                        }" @click="currentData.gambar1 && openInNewTab(currentData.gambar1)"
                                        class="rounded-1">
                                    </div>
                                    <div style="width: 100px; height: 100px; background-size: cover; background-position: center; cursor: pointer;"
                                        :style="{
                                            'background-image': currentData.gambar2 ? 'url(' + currentData.gambar2 + ')' : 'url(' + require('@/assets/img/img_default_2.png') + ')',
                                            'border': currentData.gambar2 ? '1px solid #043249' : 'none'
                                        }" @click="currentData.gambar2 && openInNewTab(currentData.gambar2)"
                                        class="rounded-1">
                                    </div>
                                    <div style="width: 100px; height: 100px; background-size: cover; background-position: center; cursor: pointer;"
                                        :style="{
                                            'background-image': currentData.gambar3 ? 'url(' + currentData.gambar3 + ')' : 'url(' + require('@/assets/img/img_default_2.png') + ')',
                                            'border': currentData.gambar3 ? '1px solid #043249' : 'none'
                                        }" @click="currentData.gambar3 && openInNewTab(currentData.gambar3)"
                                        class="rounded-1">
                                    </div>


                                </div>

                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>No Form <span class="text-danger">*</span></label>
                                        <input type="text" v-model="currentData.no_form" placeholder="" required
                                            disabled />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Kategori Maintenance<span class="text-danger">*</span></label>
                                        <input type="text" v-model="currentData.kategori" placeholder="" required
                                            disabled />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Jenis Maintenance <span class="text-danger">*</span></label>
                                        <input type="text" v-model="currentData.jenis" placeholder="" required
                                            disabled />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Keluhan </label>
                                        <textarea class="form-control" v-model="currentData.keluhan" disabled>
                    </textarea>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Tindakan <span class="text-danger">*</span></label>
                                        <vue-select :options="actionList"
                                            :settings="{ settingOption: value, settingOption: value }"
                                            v-model="currentData.tindakan" placeholder="Pilih tindakan">
                                        </vue-select>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Nama Bengkel</label>
                                        <input type="text" v-model="currentData.bengkel" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Nama Mekanik</label>
                                        <input type="text" v-model="currentData.mekanik" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Keterangan </label>
                                        <textarea class="form-control" v-model="currentData.keterangan">
                    </textarea>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Estimasi Biaya</label>
                                        <input type="text" class="mb-1" v-model="currentData.service"
                                            placeholder="Rp. 0" @input="formatCurrency('service', currentData.service)"
                                            required />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>SparePart</label>
                                        <input type="text" class="mb-1" v-model="currentData.sparepart"
                                            placeholder="Rp. 0 "
                                            @input="formatCurrency('sparepart', currentData.sparepart)" required />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Biaya Lainnya</label>
                                        <input type="text" class="mb-1" v-model="currentData.others" placeholder="Rp. 0"
                                            @input="formatCurrency('others', currentData.others)" required />
                                    </div>
                                </div>

                                <div class="col-lg-3 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label>Lama Pengerjaan <span class="text-danger">*</span></label>
                                        <div class="position-relative w-100">
                                            <input type="number" v-model="currentData.duration" placeholder="" />
                                            <div
                                                class="position-absolute top-0 end-0 me-4 pe-2 h-100 d-flex align-items-center text-secondary">
                                                / hari</div>
                                        </div>
                                    </div> -->
                            </div>

                            <div class="col-lg-12">
                                <button type="submit" class="btn btn-submit me-2">Simpan</button>
                                <router-link to="request" class="btn btn-cancel">Batal</router-link>
                            </div>

                        </form>
                    </div>
                </div>
                <!-- /add -->
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
    // components: {
    //   Form,
    //   Field,
    // },
    name: "editmarketing",
    data() {
        return {
            filter: true,
            title: "Form Perbaikan",
            title1: "",
            config: "",
            api: "",
            callback: "marketings",

            //data
            currentData: {
            },
            currentDate: new Date,

            actionList: ["Arahkan ke bengkel", "Kirim mekanik", "Evakuasi"],
        };
    },

    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: {
                Authorization: `Bearer ` + token,
            },
            timeout: 30000,
        }
        if (localStorage.getItem('callback') != null) {
            this.callback = localStorage.getItem('callback');
        }

        // needData1
        this.currentData = JSON.parse(localStorage.getItem('needData1'));
        this.title = (this.title + " " + this.currentData.no_pol + " | " + this.currentData.nama_driver).toUpperCase();
        this.title1 = this.currentData.date + " • " + this.currentData.time;



    },
    mounted() {
        localStorage.removeItem('callback');
    },
    // setup() {

    //   const schema = Yup.object().shape({
    //     name: Yup.string().required("Phone Number is required"),

    //   });

    //   return {
    //     schema,

    //   };
    // },
    methods: {
        openInNewTab(text) {
            window.open(text, "_blank");
        },
        formatCurrency(label, number) {
            this.currentData[label] = rupiahInput(number);
        },
        getNameFileUrl(url) {
            const parts = url.split('/');
            const fileName = parts[parts.length - 1];
            return fileName;
        },
        update() {
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'maintenance_update';
            } else {
                this.api = base_url + 'maintenance_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        CloseLoading();
                        goPage('request');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },



    },

};
</script>
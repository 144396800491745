<template>
    <!-- Mobile Menu -->
    <div class="dropdown mobile-user-menu">
        <a href="javascript:void(0);" class="nav-link dropdown-toggle text-white" data-bs-toggle="dropdown"
            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
        <div class="dropdown-menu dropdown-menu-right">
            <router-link class="dropdown-item" to="profile">My Profile</router-link>
            <!-- <router-link class="dropdown-item" to="generalsettings">Settings</router-link> -->
            <!-- <router-link class="dropdown-item" to="/">Logout</router-link> -->
            <a class="dropdown-item" @click="logOut">
                Logout
            </a>
        </div>
    </div>
    <!-- /Mobile Menu -->
</template>

<script>
import Cookies from "js-cookie";
import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, goPage } from "@/assets/js/function";
import axios from "axios";

export default {
    data() {
        return {

        };
    },
    methods: {
        logOut() {
            ShowLoading();
            // let formData = new FormData();
            // formData.append("", 'pengajuan');
            let api = "";
            if (process.env.NODE_ENV === "development") {
                api = base_url + 'logout';
            } else {
                api = base_url + 'logout';
            }
            const config = {
                headers: { Authorization: `Bearer ` + Cookies.get("token_refresh") },
                timeout: 30000,
            }

            axios
                .post(api, '', config)
                .then((response) => {
                    if (response.data.status == true) {
                        Cookies.set("token_refresh", "");
                        CloseLoading();
                        goPage('');
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
};
</script>
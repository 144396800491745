<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal<span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.tanggal" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Isi <span class="text-danger">*</span></label>
                    <textarea class="form-control" v-model="currentData.deskripsi">
                    </textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="news" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, base_url_wilayah_indonesia } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Tambah Berita",
      title1: "add berita baru",
      config: "",
      api: "",

      //data
      currentData: {
        exp_sim: new Date,
      },
      GenderList: ["L", "P"],
      SimList: ["A", "B I", "B II", "C", "D"],
      provinsiList: [],
      kabupatenList: [],
      kecamatanList: [],
      kelurahanList: [],

    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }
    this.currentData.tanggal = new Date();



  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },

  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("tanggal");

      if (this.currentData.tanggal != 'Invalid Date' && this.currentData.tanggal != new Date()) {
        var formattedDate = this.currentData.tanggal.toISOString().split('T')[0];
        formData.append("tanggal", formattedDate);
      } else {
        formData.append("tanggal", null);
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'newsinsert';
      } else {
        this.api = base_url + 'newsinsert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("news");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },


  },

};
</script>

<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <!-- <pageaddheader :title="title" :title1="title1" :items="items" /> -->
                <pageaddheader :title="title" :title1="title1" />
                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div
                            class="table-top flex justify-content-between align-items-center border-bottom border-2 border-gray pb-2">

                            <div
                                class="d-flex justify-content-start align-items-center gap-4 order-2 order-md-1 mt-4 mt-md-0">
                                <div class="fw-bold fs-6  position-relative" style="cursor: pointer;"
                                    @click="activePage('trip'); index();"
                                    :class="{ 'text-primary': active_page === 'trip', 'text-secondary': active_page !== 'trip' }">
                                    Pengajuan trip <span class="badge text-bg-danger pb-1"
                                        v-show="active_page == 'trip'">{{
                                            data.length }}</span>
                                    <hr class="position-absolute d-md-none"
                                        style="border: 1px solid #043249; opacity: 1!important; width: 150px; left: -20px; bottom: -25px;"
                                        v-if="active_page == 'trip'">
                                    <hr class="position-absolute d-none d-md-block"
                                        style="border: 1px solid #043249; opacity: 1!important; width: 150px; left: -20px;"
                                        v-if="active_page == 'trip'">
                                </div>
                                <div class="fw-bold fs-6 text-secondary position-relative" style="cursor: pointer;"
                                    @click="activePage('maintenance'); index_maintenance();"
                                    :class="{ 'text-primary': active_page === 'maintenance', 'text-secondary': active_page !== 'maintenance' }">
                                    Maintenance <span class="badge text-bg-danger pb-1"
                                        v-show="active_page == 'maintenance'">{{ data_maintenance.length
                                        }}</span>
                                    <hr class="position-absolute d-md-none"
                                        style="border: 1px solid #043249; opacity: 1!important; width: 150px; left: -20px; bottom: -25px;"
                                        v-if="active_page == 'maintenance'">
                                    <hr class="position-absolute d-none d-md-block"
                                        style="border: 1px solid #043249; opacity: 1!important; width: 150px; left: -20px;"
                                        v-if="active_page == 'maintenance'">
                                </div>
                            </div>
                            <div class=" search-set order-1 order-md-2 ">
                                <!-- <div class=" search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="@/assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="@/assets/img/icons/printer.svg" alt="img" /></a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- /Filter -->
                        <!-- <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div v-show="active_page == 'trip'">
                            <div v-for="(item, index) in filteredData" :key="index"
                                class="border-1 border-bottom mb-3 d-flex justify-content-between">
                                <div class="d-flex gap-2 mb-3">
                                    <div>
                                        <img src="../../../assets/img/icon-user.png" alt="" width="60">
                                    </div>
                                    <div>
                                        <div class="fw-bold text-uppercase ">
                                            {{ item.no_pol }} || {{ item.nama_driver }}
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.date }} • {{ item.time }}
                                        </div>
                                        <div class="fs-6 mb-2">
                                            {{ item.judul }}
                                        </div>
                                        <div class="d-flex gap-2">
                                            <div class="btn btn-outline-primary py-1 px-5 text-danger"
                                                v-if="item.status_progress == 'PENDING'">
                                                Tolak
                                            </div>
                                            <div class="btn btn-primary py-1 px-5 "
                                                v-if="item.status_progress == 'PENDING'" @click="edit(item)">
                                                Setujui
                                            </div>
                                            <div class="text-success" v-if="item.status_progress != 'PENDING'">{{
                                                item.status_progress }}</div>

                                        </div>


                                    </div>
                                </div>
                                <div class="d-flex align-items-center ">
                                    <i class="ion ion-record text-danger" v-if="item.status_progress == 'PENDING'"
                                        data-bs-toggle="tooltip" title="ion-record"></i>
                                </div>
                            </div>
                        </div>
                        <div v-show="active_page == 'maintenance'">
                            <div v-for="(item, index) in filteredData" :key="index"
                                class="border-1 border-bottom mb-3 d-flex justify-content-between">
                                <div class="d-flex gap-2 mb-3">
                                    <div>
                                        <img src="../../../assets/img/icon-user.png" alt="" width="60">
                                    </div>
                                    <div>
                                        <div class="fw-bold text-uppercase ">
                                            {{ item.no_pol }} || {{ item.nama_driver }}
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.date }} • {{ item.time }}
                                        </div>
                                        <div class="fs-6 mb-2">
                                            {{ item.judul }}
                                        </div>
                                        <div>
                                            <div class="d-flex gap-2 mb-2 align-items-center" v-if="item.gambar1 != ''"
                                                @click="openInNewTab(item.gambar1)" style="cursor:pointer">
                                                <div>
                                                    <img src="../../../assets/img/img_default.png" alt="" width="30">
                                                </div>
                                                <div>
                                                    Gambar 1
                                                </div>
                                            </div>
                                            <div class="d-flex gap-2 mb-2 align-items-center" v-if="item.gambar2 != ''"
                                                @click="openInNewTab(item.gambar2)" style="cursor:pointer">
                                                <div>
                                                    <img src="../../../assets/img/img_default.png" alt="" width="30">
                                                </div>
                                                <div>
                                                    Gambar 2
                                                </div>
                                            </div>
                                            <div class="d-flex gap-2 mb-2 align-items-center" v-if="item.gambar3 != ''"
                                                @click="openInNewTab(item.gambar3)" style="cursor:pointer">
                                                <div>
                                                    <img src="../../../assets/img/img_default.png" alt="" width="30">
                                                </div>
                                                <div>
                                                    Gambar 3
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex gap-2">
                                            <!-- <div class="btn btn-outline-primary py-1 px-5 text-danger"
                                                v-if="item.status_progress == 'PENDING'">
                                                Tolak
                                            </div> -->

                                            <div class="btn btn-primary py-1 px-5 " style="cursor: pointer;"
                                                @click="maintenanceProcess(item); goPage('add_maintenance')"
                                                v-if="item.status_progress == 'PENDING'">
                                                Setujui
                                            </div>
                                            <div class="text-success" v-if="item.status_progress != 'PENDING'">{{
                                                item.tindakan }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center " v-if="item.status_progress == 'PENDING'">
                                    <i class="ion ion-record text-danger" data-bs-toggle="tooltip"
                                        title="ion-record"></i>

                                </div>
                                <div class="d-flex align-items-start justify-content-end "
                                    v-if="item.status_progress != 'PENDING'">
                                    <img src="../../../assets/img/edit_icon.png" alt="" style="cursor:pointer"
                                        @click="maintenanceProcess(item); goPage('add_maintenance')">
                                </div>
                            </div>
                        </div>
                        <!-- /Filter -->
                        <!-- <div class="table-responsive">
                            <a-table :columns="columns" :data-source="data" @change="onChange">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
<template v-if="column.key === 'tanggal'">
                                        <div class="productimgname">
                                            <a href="javascript:void(0);">{{ record.created_at }}</a>
                                        </div>
                                    </template>
<template v-if="column.key === 'judul'">
                                        <div class="productimgname">
                                            {{ record.judul }}
                                        </div>
                                       
                                    </template>
<template v-if="column.key === 'status_progress'">
                                        <div class="productimgname">
                                            <a href="javascript:void(0);"
                                                :class="{ 'text-warning': record.status_progress == 'PENDING', 'text-success': record.status_progress == 'SETUJU', 'text-danger': record.status_progress == 'TOLAK' }">{{
                                                    record.status_progress }}</a>
                                        </div>
                                    </template>

<template v-else-if="column.key === 'action'">

                                        <a class="me-3 text-success" @click="edit(record)"
                                            v-if="record.status_progress == 'PENDING'">
                                            <vue-feather type="check"></vue-feather>
                                        </a>

                                    </template>
</template>
</a-table>
</div> -->
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="modal-select-trip" ref="modal-select-trip" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Pilih Trip</h5>
                    <button type="button" class="close" @click="hideModal2('modal-select-trip')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                    <div class="modal-body">
                        Trip mana yang akan digunakan
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <!-- <div class="">
                            <button type="button" class="btn  btn-secondary"
                                @click="hideModal2('modal-select-trip');">Batal</button>
                        </div> -->
                        <div class="d-flex justify-content-end gap-2">
                            <button type="button" class="btn btn-primary"
                                @click="hideModal2('modal-select-trip'); goToPage('addtripreguler')">Trip
                                Reguler</button>
                            <button type="button" class="btn btn-success"
                                @click="hideModal2('modal-select-trip'); goToPage('addtripkhusus')">Trip Khusus</button>
                        </div>


                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, goPage } from "@/assets/js/function";

export default {
    data() {
        return {
            filter: false,
            title: "Daftar Pengajuan Trip",
            title1: "atur pengajuan trip ",

            query: "",
            // items: [
            //     {
            //         text: "Tambah",
            //         addname: "/addjobtittle",
            //     },
            // ],

            //data
            unreadNotif: 0,
            pollingInterval: null,
            data: [],
            data_maintenance: [],
            // columns: [
            //     {
            //         title: "No",
            //         dataIndex: "no",
            //         key: "no",
            //         sorter: {
            //             compare: (a, b) => {
            //                 a = a.no.toLowerCase();
            //                 b = b.no.toLowerCase();
            //                 return a > b ? -1 : b > a ? 1 : 0;
            //             },
            //         },
            //     },
            //     {
            //         title: "Date",
            //         dataIndex: "created_at",
            //         key: "created_at",
            //         sorter: {
            //             compare: (a, b) => {
            //                 a = a.created_at.toLowerCase();
            //                 b = b.created_at.toLowerCase();
            //                 return a > b ? -1 : b > a ? 1 : 0;
            //             },
            //         },
            //     },
            //     {
            //         title: "Title",
            //         dataIndex: "judul",
            //         key: "judul",
            //         sorter: {
            //             compare: (a, b) => {
            //                 a = a.judul.toLowerCase();
            //                 b = b.judul.toLowerCase();
            //                 return a > b ? -1 : b > a ? 1 : 0;
            //             },
            //         },
            //     },

            //     {
            //         title: "Status",
            //         dataIndex: "status_progress",
            //         key: "status_progress",
            //         sorter: {
            //             compare: (a, b) => {
            //                 a = a.status_progress.toLowerCase();
            //                 b = b.status_progress.toLowerCase();
            //                 return a > b ? -1 : b > a ? 1 : 0;
            //             },
            //         },
            //     },
            //     {
            //         title: "Action",
            //         key: "action",
            //         sorter: false,
            //     },
            // ],

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",
            active_page: "trip",
        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        if (localStorage.getItem("active_page") == null) {
            localStorage.setItem("active_page", "request");
        } else {
            this.active_page = localStorage.getItem('active_page');
            if (this.active_page == 'request') {
                index();
            } else {
                this.index_maintenance();
            }
        }

    },
    async mounted() {
        try {
            const db = await this.openIndexedDB();
            const newValue = await this.setNotifSum(db);

        } catch (error) {
            console.error("Error initializing IndexedDB in mounted:", error);
        }
    },

    computed: {
        filteredData() {
            if (this.active_page == 'trip') {
                if (this.query == '') {
                    return this.data; // If no filter selected, return all data
                }
                const lowerCaseQuery = this.query.toLowerCase();
                return this.data.filter(item =>
                    (item.judul?.toLowerCase().includes(lowerCaseQuery) || '') ||
                    (item.nama_driver?.toLowerCase().includes(lowerCaseQuery) || '') ||
                    (item.no_pol?.toLowerCase().includes(lowerCaseQuery) || '')
                );


            } else {
                if (this.query == '') {
                    return this.data_maintenance; // If no filter selected, return all data
                }
                const lowerCaseQuery = this.query.toLowerCase();
                return this.data_maintenance.filter(item =>
                    (item.judul?.toLowerCase().includes(lowerCaseQuery) || '') ||
                    (item.nama_driver?.toLowerCase().includes(lowerCaseQuery) || '') ||
                    (item.no_pol?.toLowerCase().includes(lowerCaseQuery) || '')
                );



            }

        },
    },

    methods: {
        goPage,
        activePage(page) {
            this.active_page = page;
            localStorage.setItem("active_page", page)
        },
        openInNewTab(text) {
            window.open(text, "_blank");
        },
        openIndexedDB() {
            return new Promise((resolve, reject) => {
                const request = indexedDB.open("notificationsDB", 1);

                request.onerror = (event) => {
                    console.error("IndexedDB error: ", event.target.errorCode);
                    reject(event.target.errorCode);
                };

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onupgradeneeded = (event) => {
                    const db = event.target.result;
                    if (!db.objectStoreNames.contains("notifications")) {
                        db.createObjectStore("notifications", { keyPath: "id", autoIncrement: true });
                    }
                };
            });
        },
        setNotifSum(db, sum) {
            return new Promise((resolve, reject) => {
                const transaction = db.transaction(["notifications"], "readwrite");
                const objectStore = transaction.objectStore("notifications");

                const getRequest = objectStore.get(1);

                getRequest.onsuccess = (event) => {
                    // let notifSum = event.target.result ? event.target.result.sum : 0;
                    let notifSum = 0;

                    const putRequest = objectStore.put({ id: 1, sum: notifSum });

                    putRequest.onsuccess = () => {
                        resolve(notifSum);
                    };

                    putRequest.onerror = (event) => {
                        console.error("Error updating notification count:", event.target.errorCode);
                        reject(event.target.errorCode);
                    };
                };

                getRequest.onerror = (event) => {
                    console.error("Error fetching notification count:", event.target.errorCode);
                    reject(event.target.errorCode);
                };
            });
        },
        show_izitoast() {
            AlertBottom('tessst');
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },
        checkFileType(url) {
            if (url != null) {


                const extension = url.split('.').pop().toLowerCase();

                if (extension.match(/(jpg|jpeg|png)$/)) {
                    return 'image';
                } else if (extension === 'pdf') {
                    return 'pdf';
                } else {
                    return 'unknown';
                }
            } else {
                return 'unknown';
            }
        },


        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#043249",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },

        index() {
            ShowLoading();
            let formData = new FormData();
            formData.append("jenis", 'pengajuan');

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'request';
            } else {
                this.api = base_url + 'request';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.data = [];

                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });

                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        index_maintenance() {
            ShowLoading();
            let formData = new FormData();
            // formData.append("jenis", 'pengajuan');

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'maintenance';
            } else {
                this.api = base_url + 'maintenance';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.data_maintenance = [];
                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data_maintenance.push(item_prepare);
                        });
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        proses(token, value, jenis_form) {
            ShowLoading();
            let formData = new FormData();
            formData.append("token", token);
            formData.append("setuju", value);



            let url = 'proses_' + jenis_form.toLowerCase();;
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + url;
            } else {
                this.api = base_url + url;
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex(
                            (record) => record.token == token
                        );
                        this.data.splice(index, 1, response.data.data);
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        edit(record) {
            localStorage.setItem('approveData', JSON.stringify(record));
            this.showModal('modal-select-trip');
        },
        maintenanceProcess(record) {
            localStorage.setItem('needData1', JSON.stringify(record));

        },
        goToPage(halaman) {
            goPage(halaman);

        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            formData.append("nama", this.nama_terminate);


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'jabatan/delete';
            } else {
                this.api = base_url + 'jabatan/delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id, nama) {
            this.id_terminate = id;
            this.nama_terminate = nama;
            this.tanggal_terminate = new Date();
        }
    },
    name: "productlist",
};
</script>
<style>
.ant-tooltip {
    display: none !important;
}
</style>
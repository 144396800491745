<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Kendaraan<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis <span class="text-danger">*</span></label>
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="needData2" placeholder="Pilih Kategori"
                          v-model="currentData.id_kategori_aset" style="z-index: 501;">
                        </model-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Supir <span class="text-danger">*</span></label>
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="needData1" placeholder="Pilih Supir" v-model="currentData.id_driver"
                          style="z-index: 502;">
                        </model-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No Polisi<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.no_pol" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Kadaluarsa STNK </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.exp_stnk" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Uji Kir</label>
                    <input type="text" v-model="currentData.uji_kir" placeholder="" />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Kadaluarsa KIR </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.exp_kir" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Imei</label>
                    <input type="text" v-model="currentData.imei" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No GSM</label>
                    <input type="text" v-model="currentData.no_gsm" placeholder="" />
                  </div>
                </div>

                <!-- jenis ban merk dll berdasarkan kategori kendaraan -->
                <pageheader title="Ban Depan" title1="" v-if="no_seri_ban_depan != ''" />
                <div v-for="(item, index) in no_seri_ban_depan" :key="item.id" class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Jenis Ban {{ index + 1 }}</label>
                      <div class="select-split">
                        <div class="select-group w-100">
                          <model-select :options="jenisBanList" placeholder="Pilih Jenis Ban"
                            v-model="item.jenis_ban_depan" :style="{ zIndex: 600 - index }">
                          </model-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Merek Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.merk_ban_depan"
                        @input="preventComma('ban_depan', index, 'merk_ban_depan')" placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ukuran Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.ukuran_ban_depan"
                        @input="preventComma('ban_depan', index, 'ukuran_ban_depan')" placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No Seri Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.no_seri_ban_depan"
                        @input="preventComma('ban_depan', index, 'no_seri_ban_depan')" placeholder="" />
                    </div>
                  </div>
                </div>

                <!-- for ban belakang -->
                <pageheader title="Ban Belakang" title1="" v-if="no_seri_ban_belakang != ''" />
                <div v-for="(item, index) in no_seri_ban_belakang" :key="item.id" class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Jenis Ban {{ index + 1 }}</label>
                      <div class="select-split">
                        <div class="select-group w-100">
                          <model-select :options="jenisBanList" placeholder="Pilih Jenis Ban"
                            v-model="item.jenis_ban_belakang" :style="{ zIndex: 500 - index }">
                          </model-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Merek Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.merk_ban_belakang" placeholder=""
                        @input="preventComma('ban_belakang', index, 'merk_ban_belakang')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ukuran Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.ukuran_ban_belakang" placeholder=""
                        @input="preventComma('ban_belakang', index, 'ukuran_ban_belakang')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No Seri Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.no_seri_ban_belakang" placeholder=""
                        @input="preventComma('ban_belakang', index, 'no_seri_ban_belakang')" />
                    </div>
                  </div>
                </div>

                <!-- for ban serap -->
                <pageheader title="Ban Serap" title1="" v-if="no_seri_ban_serap != ''" />
                <div v-for="(item, index) in no_seri_ban_serap" :key="item.id" class="row">
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Jenis Ban {{ index + 1 }}</label>
                      <div class="select-split">
                        <div class="select-group w-100">
                          <model-select :options="jenisBanList" placeholder="Pilih Jenis Ban"
                            v-model="item.jenis_ban_serap" :style="{ zIndex: 400 - index }">
                          </model-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Merek Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.merk_ban_serap" placeholder=""
                        @input="preventComma('ban_serap', index, 'merk_ban_serap')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Ukuran Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.ukuran_ban_serap" placeholder=""
                        @input="preventComma('ban_serap', index, 'ukuran_ban_serap')" />
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No Seri Ban {{ index + 1 }}</label>
                      <input type="text" v-model="item.no_seri_ban_serap" placeholder=""
                        @input="preventComma('ban_serap', index, 'no_seri_ban_serap')" />
                    </div>
                  </div>
                </div>

                <!-- List Perlengkapan -->
                <pageheader title="List Perlengkapan" title1="" />
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_dongkrak > 0
                    }" value="Dongkrak" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_dongkrak"
                      @input="checkStatus(currentData.qty_dongkrak, 'status_dongkrak')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_dongkrak" placeholder=""
                      disabled />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_stik_dongkrak > 0
                    }" value="Stik Dongkrak" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_stik_dongkrak"
                      @input="checkStatus(currentData.qty_stik_dongkrak, 'status_stik_dongkrak')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_stik_dongkrak" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_ban_serap > 0
                    }" value="Ban Serap" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_ban_serap"
                      @input="checkStatus(currentData.qty_ban_serap, 'status_ban_serap')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_ban_serap" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_aki_2_unit > 0
                    }" value="Aki 2 Unit" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_aki_2_unit"
                      @input="checkStatus(currentData.qty_aki_2_unit, 'status_aki_2_unit')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_aki_2_unit" placeholder="" />
                  </div> -->
                </div>

                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_apd > 0
                    }" value="APD" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_apd"
                      @input="checkStatus(currentData.qty_apd, 'status_apd')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_apd" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_kotak_p3k > 0
                    }" value="Kotak P3K" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_kotak_p3k"
                      @input="checkStatus(currentData.qty_kotak_p3k, 'status_kotak_p3k')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_kotak_p3k" placeholder="" />
                  </div> -->
                </div>
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_lasing > 0
                    }" value="Lasing" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_lasing"
                      @input="checkStatus(currentData.qty_lasing, 'status_lasing')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_lasing" placeholder="" />
                  </div> -->
                </div>

                <!-- seatbelt -->
                <div class="col-lg-3 col-sm-3 col-6 ">
                  <div class="form-group" style="color: white!important;;">
                    <input type="text" class="w-full" :class="{
                      'bg-active': currentData.qty_seatbelt > 0
                    }" value="Seatbelt" style=" text-align: center!important;" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-6 d-flex align-items-center gap-3">
                  <div class="form-group">
                    <input type="number" class="w-full" v-model="currentData.qty_seatbelt"
                      @input="checkStatus(currentData.qty_seatbelt, 'status_seatbelt')" placeholder="" />
                  </div>
                  <!-- <div class="form-group">
                    <input type="checkbox" class="w-full" v-model="currentData.status_seatbelt" placeholder="" />
                  </div> -->
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="asset" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-active {
  background-color: #043249 !important;
  color: white !important;
}
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Tambah Kendaraan",
      title1: "tambah kendaraan baru",
      config: "",
      api: "",
      jenisBanList: [
        {
          value: "Ban Mentah",
          text: "Ban Mentah"
        },
        {
          value: "Ban Masak",
          text: "Ban Masak"
        },

        {
          value: "Ban Benang",
          text: "Ban Benang"
        },
        {
          value: "Ban Kawat",
          text: "Ban Kawat"
        },

      ],

      //data
      currentData: {
        exp_sim: new Date,
      },

      needData1: [],
      needData2: [],

      no_seri_ban_depan: [],
      no_seri_ban_belakang: [],
      no_seri_ban_serap: [],
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.prepareData();

  },
  watch: {
    'currentData.id_kategori_aset': {
      handler(newValue) {
        this.checkKategoriAset(newValue);
      }
    },
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    preventComma(jenis, index, field) {
      if (jenis == 'ban_depan') {
        this.no_seri_ban_depan[index][field] = this.no_seri_ban_depan[index][field].replace(/,/g, "");
      } else if (jenis == 'ban_belakang') {
        this.no_seri_ban_belakang[index][field] = this.no_seri_ban_belakang[index][field].replace(/,/g, "");
      } else {
        this.no_seri_ban_serap[index][field] = this.no_seri_ban_serap[index][field].replace(/,/g, "");
      }

    },
    checkKategoriAset(id) {
      const index = this.needData2.findIndex((record) => record.value === id);
      let jenis_ban_default = '';
      let ukuran_ban_default = '';
      if (this.needData2[index].status_ban_depan_belakang_sama == 'AKTIF') {
        jenis_ban_default = 'Ban Benang';
        ukuran_ban_default = this.needData2[index].ukuran_ban;
      } else {
        jenis_ban_default = '';
        ukuran_ban_default = this.needData2[index].ukuran_ban;
      }




      if (index > -1) {
        const jumlah_ban_depan = this.needData2[index].jumlah_ban_depan;
        const jumlah_ban_belakang = this.needData2[index].jumlah_ban_belakang;
        const jumlah_ban_serap = this.needData2[index].jumlah_ban_serap;
        const jumlah_ban_depan_seharusnya_1 = jumlah_ban_depan - this.no_seri_ban_depan.length;
        const jumlah_ban_depan_seharusnya_2 = this.no_seri_ban_depan.length - jumlah_ban_depan;
        if (jumlah_ban_depan == this.no_seri_ban_depan.length) {

        } else if (jumlah_ban_depan == 0) {
          this.no_seri_ban_depan = [];

        } else if (jumlah_ban_depan > this.no_seri_ban_depan.length && this.no_seri_ban_depan.length != 0) {
          for (var i = 0; i < jumlah_ban_depan_seharusnya_1; i++) {
            this.no_seri_ban_depan.push({ id: i, no_seri_ban_depan: '', ukuran_ban_depan: this.needData2[index].ukuran_ban, jenis_ban_depan: jenis_ban_default });

          }
        } else if (jumlah_ban_depan < this.no_seri_ban_depan.length && this.no_seri_ban_depan.length != 0) {
          for (var i = 0; i < jumlah_ban_depan_seharusnya_2; i++) {
            this.no_seri_ban_depan.pop();
          }
        } else {
          for (var i = 1; i <= jumlah_ban_depan; i++) {
            this.no_seri_ban_depan.push({ id: i, no_seri_ban_depan: '', ukuran_ban_depan: this.needData2[index].ukuran_ban, jenis_ban_depan: jenis_ban_default });

          }
        }

        // for ban belakang
        const indexBelakang = this.needData2.findIndex((record) => record.value === id);
        const jumlah_ban_belakang_seharusnya_1 = jumlah_ban_belakang - this.no_seri_ban_belakang.length;
        const jumlah_ban_belakang_seharusnya_2 = this.no_seri_ban_belakang.length - jumlah_ban_belakang;
        if (indexBelakang > -1) {
          const jumlah_ban_belakang = this.needData2[indexBelakang].jumlah_ban_belakang;
          // const jumlah_ban_belakang = this.needData2[index].jumlah_ban_belakang + this.needData2[index].jumlah_ban_serap;
          if (jumlah_ban_belakang == this.no_seri_ban_belakang.length) {

          } else if (jumlah_ban_belakang == 0) {
            this.no_seri_ban_belakang = [];

          } else if (jumlah_ban_belakang > this.no_seri_ban_belakang.length && this.no_seri_ban_belakang.length != 0) {
            for (var i = 0; i < jumlah_ban_belakang_seharusnya_1; i++) {
              this.no_seri_ban_belakang.push({ id: i, no_seri_ban_belakang: '', ukuran_ban_belakang: this.needData2[indexBelakang].ukuran_ban, jenis_ban_belakang: jenis_ban_default });

            }
          } else if (jumlah_ban_belakang < this.no_seri_ban_belakang.length && this.no_seri_ban_belakang.length != 0) {
            for (var i = 0; i < jumlah_ban_belakang_seharusnya_2; i++) {
              this.no_seri_ban_belakang.pop();
            }
          } else {
            for (var i = 1; i <= jumlah_ban_belakang; i++) {
              this.no_seri_ban_belakang.push({ id: i, no_seri_ban_belakang: '', ukuran_ban_belakang: this.needData2[indexBelakang].ukuran_ban, jenis_ban_belakang: jenis_ban_default });

            }
          }
        }

        // for ban serap
        const indexSerap = this.needData2.findIndex((record) => record.value === id);
        if (indexSerap > -1) {
          const jumlah_ban_serap = this.needData2[indexSerap].jumlah_ban_serap;
          const jumlah_ban_serap_seharusnya_1 = jumlah_ban_serap - this.no_seri_ban_serap.length;
          const jumlah_ban_serap_seharusnya_2 = this.no_seri_ban_serap.length - jumlah_ban_serap;

          if (jumlah_ban_serap == this.no_seri_ban_serap.length) {

          } else if (jumlah_ban_serap == 0) {
            this.no_seri_ban_serap = [];

          } else if (jumlah_ban_serap > this.no_seri_ban_serap.length && this.no_seri_ban_serap.length != 0) {
            for (var i = 0; i < jumlah_ban_serap_seharusnya_1; i++) {
              this.no_seri_ban_serap.push({ id: i, no_seri_ban_serap: '', ukuran_ban_serap: this.needData2[indexSerap].ukuran_ban, jenis_ban_serap: jenis_ban_default });

            }
          } else if (jumlah_ban_serap < this.no_seri_ban_serap.length && this.no_seri_ban_serap.length != 0) {
            for (var i = 0; i < jumlah_ban_serap_seharusnya_2; i++) {
              this.no_seri_ban_serap.pop();
            }
          } else {
            for (var i = 1; i <= jumlah_ban_serap; i++) {
              this.no_seri_ban_serap.push({ id: i, no_seri_ban_serap: '', ukuran_ban_serap: this.needData2[indexSerap].ukuran_ban, jenis_ban_serap: jenis_ban_default });

            }
          }
        }

        if (this.needData2[index].status_ban_depan_belakang_sama == 'AKTIF') {

          this.no_seri_ban_depan.forEach((item, index) => {
            this.no_seri_ban_depan[index].jenis_ban_depan = jenis_ban_default;
            this.no_seri_ban_depan[index].ukuran_ban_depan = ukuran_ban_default;
          });

          this.no_seri_ban_belakang.forEach((item, index) => {
            this.no_seri_ban_belakang[index].jenis_ban_belakang = jenis_ban_default;
            this.no_seri_ban_belakang[index].ukuran_ban_belakang = ukuran_ban_default;
          });
          this.no_seri_ban_serap.forEach((item, index) => {
            this.no_seri_ban_serap[index].jenis_ban_serap = jenis_ban_default;
            this.no_seri_ban_serap[index].ukuran_ban_serap = ukuran_ban_default;
          });
        } else {
          this.no_seri_ban_depan.forEach((item, index) => {
            this.no_seri_ban_depan[index].ukuran_ban_depan = ukuran_ban_default;
          });

          this.no_seri_ban_belakang.forEach((item, index) => {
            this.no_seri_ban_belakang[index].ukuran_ban_belakang = ukuran_ban_default;
          });
          this.no_seri_ban_serap.forEach((item, index) => {
            this.no_seri_ban_serap[index].ukuran_ban_serap = ukuran_ban_default;
          });
        }

      }


    },
    checkStatus(qty, field) {
      if (qty > 0) {
        this.currentData[field] = true;
      } else {
        this.currentData[field] = false;
      }
    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {




      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("exp_stnk");

      if (this.currentData.exp_stnk != undefined && this.currentData.exp_stnk != 'Invalid Date' && this.currentData.exp_stnk != new Date()) {
        var formattedDate = this.currentData.exp_stnk.toISOString().split('T')[0];
        formData.append("exp_stnk", formattedDate);
      } else {
        formData.append("exp_stnk", null);
      }

      const no_seri_ban_depan = this.no_seri_ban_depan.map(item => item.no_seri_ban_depan);
      formData.delete("no_seri_ban_depan");
      formData.append("no_seri_ban_depan", no_seri_ban_depan.join(', '));

      const jenis_ban_depan = this.no_seri_ban_depan.map(item => item.jenis_ban_depan);
      formData.delete("jenis_ban_depan");
      formData.append("jenis_ban_depan", jenis_ban_depan.join(', '));

      const ukuran_ban_depan = this.no_seri_ban_depan.map(item => item.ukuran_ban_depan);
      formData.delete("ukuran_ban_depan");
      formData.append("ukuran_ban_depan", ukuran_ban_depan.join(', '));

      const merk_ban_depan = this.no_seri_ban_depan.map(item => item.merk_ban_depan);
      formData.delete("merk_ban_depan");
      formData.append("merk_ban_depan", merk_ban_depan.join(', '));

      // for ban belakang
      const no_seri_ban_belakang = this.no_seri_ban_belakang.map(item => item.no_seri_ban_belakang);
      formData.delete("no_seri_ban_belakang");
      formData.append("no_seri_ban_belakang", no_seri_ban_belakang.join(', '));

      const jenis_ban_belakang = this.no_seri_ban_belakang.map(item => item.jenis_ban_belakang);
      formData.delete("jenis_ban_belakang");
      formData.append("jenis_ban_belakang", jenis_ban_belakang.join(', '));

      const ukuran_ban_belakang = this.no_seri_ban_belakang.map(item => item.ukuran_ban_belakang);
      formData.delete("ukuran_ban_belakang");
      formData.append("ukuran_ban_belakang", ukuran_ban_belakang.join(', '));

      const merk_ban_belakang = this.no_seri_ban_belakang.map(item => item.merk_ban_belakang);
      formData.delete("merk_ban_belakang");
      formData.append("merk_ban_belakang", merk_ban_belakang.join(', '));

      // for ban serap
      const no_seri_ban_serap = this.no_seri_ban_serap.map(item => item.no_seri_ban_serap);
      formData.delete("no_seri_ban_serap");
      formData.append("no_seri_ban_serap", no_seri_ban_serap.join(', '));

      const jenis_ban_serap = this.no_seri_ban_serap.map(item => item.jenis_ban_serap);
      formData.delete("jenis_ban_serap");
      formData.append("jenis_ban_serap", jenis_ban_serap.join(', '));

      const ukuran_ban_serap = this.no_seri_ban_serap.map(item => item.ukuran_ban_serap);
      formData.delete("ukuran_ban_serap");
      formData.append("ukuran_ban_serap", ukuran_ban_serap.join(', '));

      const merk_ban_serap = this.no_seri_ban_serap.map(item => item.merk_ban_serap);
      formData.delete("merk_ban_serap");
      formData.append("merk_ban_serap", merk_ban_serap.join(', '));

      if (this.currentData.exp_stnk != undefined && this.currentData.exp_kir != 'Invalid Date' && this.currentData.exp_kir != new Date()) {
        var formattedDate = this.currentData.exp_kir.toISOString().split('T')[0];
        formData.append("exp_kir", formattedDate);
      } else {
        formData.append("exp_kir", null);
      }


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'assetsinsert';
      } else {
        this.api = base_url + 'assetsinsert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("asset");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    prepareData() {

      // needData1
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      needData1.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        console.log(item);
        this.needData1.push(element);
      });

      // needData2
      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((item, index) => {
        const element = { value: item.id, text: item.nama, jumlah_ban_depan: item.jumlah_ban_depan, jumlah_ban_belakang: item.jumlah_ban_belakang, jumlah_ban_serap: item.jumlah_ban_serap, status_ban_depan_belakang_sama: item.status_ban_depan_belakang_sama, ukuran_ban: item.ukuran_ban }
        this.needData2.push(element);
      });


    }

  },

};
</script>

<template>
  <!-- Header Menu -->
  <ul class="nav user-menu ">
    <!-- Search -->
    <li class="nav-item nav-searchinputs">
      <div class="top-nav-search">
        <a href="javascript:void(0);" class="responsive-search">
          <i class="fa fa-search"></i>
        </a>
        <!-- <form action="#">
          <div class="searchinputs">
            <input type="text" placeholder="Search" />
            <div class="search-addon">
              <span><vue-feather type="search" class="feather-14"></vue-feather></span>
            </div>
          </div>
        </form> -->
      </div>
    </li>

    <!-- <li class="nav-item nav-item-box">
      <vue-select :options="companyList" :settings="{ settingOption: value, settingOption: value }"
        v-model="companyselected" placeholder="None">
      </vue-select>


    </li> -->

    <!-- /Search -->
    <!-- <li class="nav-item nav-item-box">
      <a @click="userGuide()"><vue-feather type="alert-circle"></vue-feather></a>
    </li> -->
    <!-- Flag -->
    <!-- <li class="nav-item dropdown has-arrow flag-nav nav-item-box">
      <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);" role="button">
        <vue-feather type="globe"></vue-feather>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
          @click="setLanguage(entry.language, entry.title, entry.iso)" :class="{ active: lan === entry.language }"
          class="dropdown-item" data-lang="en" title="English">
          <flag :iso="entry.iso" height="16" class="me-2" />
          <span class="align-middle">{{ entry.title }}</span>
        </a>
      </div>
    </li> -->
    <!-- /Flag -->
    <li class="nav-item nav-item-box">
      <a href="javascript:void(0);" id="btnFullscreen" @click="initFullScreen">
        <vue-feather type="maximize"></vue-feather>
      </a>
    </li>
    <li class="nav-item nav-item-box">
      <router-link to="request">
        <vue-feather type="bell"></vue-feather>
        <div v-show="unreadNotif > 0" class="badge rounded-pill">
          <span v-show="unreadNotif <= 99">{{ unreadNotif }}</span>
          <span v-show="unreadNotif > 99">99+</span>
        </div>
      </router-link>
    </li>


    <!-- /Notifications -->
    <li class="nav-item nav-item-box" v-if="tipe_user == 'SUP'">
      <router-link to="settingsprofile"><vue-feather type="settings"></vue-feather></router-link>
    </li>
    <li class="nav-item dropdown has-arrow main-drop">
      <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
        <span class="user-info">
          <span class="user-letter" style="background-color: transparent!important;">
            <img :src="gambar_user" alt="" class="img-fluid" />
          </span>
          <span class="user-detail">
            <span class="user-name">{{ nama_user }}</span>
            <span class="user-role">{{ tipe_user }}</span>
          </span>
        </span>
      </a>
      <div class="dropdown-menu menu-drop-user">
        <div class="profilename">
          <div class="profileset">
            <span class="user-img"><img :src="gambar_user" alt="" />
              <span class="status online"></span></span>
            <div class="profilesets">
              <h6>{{ nama_user }}</h6>
              <h5>{{ tipe_user }}</h5>
            </div>
          </div>
          <hr class="m-0" />
          <router-link class="dropdown-item" to="profile"><vue-feather type="user"></vue-feather>My
            Profile</router-link>
          <!-- <router-link class="dropdown-item" to="settingsprofile"><vue-feather
              type="settings"></vue-feather>Settings</router-link> -->
          <hr class="m-0" />
          <a class="dropdown-item logout pb-0" @click="logOut">
            <img src="../assets/img/icons/log-out.svg" class="me-2" alt="img" />Logout
          </a>
          <!-- <router-link class="dropdown-item logout pb-0" to="/">
            <img src="../assets/img/icons/log-out.svg" class="me-2" alt="img" />Logout</router-link> -->
        </div>
      </div>
    </li>
  </ul>

</template>

<style scoped>
.user-img img {
  border-radius: 50%;
  object-fit: cover;
  width: 40px;
  /* Adjust based on your needs */
  height: 40px;
  /* Adjust to keep the aspect ratio square */
}

.user-letter img {
  border-radius: 50%;
  object-fit: cover;
  width: 40px;
  /* Adjust based on your needs */
  height: 40px;
  /* Adjust to keep the aspect ratio square */
}
</style>

<script>
import headernotification from "../assets/json/headernotification.json";
import Cookies from "js-cookie";
import axios from "axios";
import debounce from "lodash/debounce";
import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, goPage, refreshPage } from "@/assets/js/function";

export default {
  data() {
    return {
      headernotification: headernotification,
      languages: [
        {
          iso: "us",
          language: "us",
          title: "English",
        },
      ],
      unreadNotif: 0,
      pollingInterval: null,
      // get unreadNotif() {
      //   return ;
      // },
      // set unreadNotif(value) {
      //   localStorage.setItem('notifSum', value);
      // },




    };
  },
  props: {
    nama_user: String,
    tipe_user: String,
    gambar_user: String,
    companyList: Object,
    companyselected: String,
  },

  emits: ["companyselected"],
  methods: {
    openIndexedDB() {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open("notificationsDB", 1);

        request.onerror = (event) => {
          console.error("IndexedDB error: ", event.target.errorCode);
          reject(event.target.errorCode);
        };

        request.onsuccess = (event) => {
          resolve(event.target.result);
        };

        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          if (!db.objectStoreNames.contains("notifications")) {
            db.createObjectStore("notifications", { keyPath: "id", autoIncrement: true });
          }
        };
      });
    },
    getNotifSum(db) {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(["notifications"], "readonly");
        const objectStore = transaction.objectStore("notifications");
        const getRequest = objectStore.get(1);

        getRequest.onsuccess = (event) => {
          resolve(event.target.result ? event.target.result.sum : 0);
        };

        getRequest.onerror = (event) => {
          console.error("Error fetching notification count:", event.target.errorCode);
          reject(event.target.errorCode);
        };
      });
    },
    async checkIndexedDB() {
      try {
        const db = await this.openIndexedDB();
        const newValue = await this.getNotifSum(db);
        if (newValue !== this.unreadNotif) {
          this.unreadNotif = newValue;
        }
      } catch (error) {
        console.error("Error checking IndexedDB:", error);
      }
    },

    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
    },
    logOut() {
      ShowLoading();
      // let formData = new FormData();
      // formData.append("", 'pengajuan');
      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'logout';
      } else {
        api = base_url + 'logout';
      }
      const config = {
        headers: { Authorization: `Bearer ` + Cookies.get("token_refresh") },
        timeout: 30000,
      }

      axios
        .post(api, '', config)
        .then((response) => {
          if (response.data.status == true) {

            Cookies.set("token_refresh", "");
            CloseLoading();
            goPage('');
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    userGuide() {
      window.open('https://support.arthaskyline.com/', "_blank");
    }
  },
  async mounted() {
    await this.checkIndexedDB();
    this.pollingInterval = setInterval(this.checkIndexedDB, 1000); // Start polling every second (adjust interval as needed)
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval); // Clean up interval on component destroy
  }


};
</script>

<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <div class="row" v-if="tipe == 'ADMIN'">
                    <!-- Chart -->
                    <!-- <div class="col-12 gap-2  d-flex align-items-start justify-content-end">
                        <div class="d-flex flex-column justify-content-center align-items-end pt-1 ">
                            <a href="javascript:void(0);" @click="exportExcel()"><img
                                    src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                            <span style="font-size: 10px; margin-top: -8px;">excel</span>
                        </div>
                        <div class="">
                            <div class="form-group">
                                
                                <div class="input-groupicon" style="z-index: 100;">
                                    <datepicker v-model="currentData.dfrom" class="picker" :editable="true"
                                        :clearable="false" required />
                                    <a class="addonset">
                                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="form-group">
                                
                                <div class="input-groupicon" style="z-index: 100;">
                                    <datepicker v-model="currentData.dto" class="picker" :editable="true"
                                        :clearable="false" required />
                                    <a class="addonset">
                                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <button class="btn btn-sm btn-primary " @click="index"> <vue-feather
                                    type="filter"></vue-feather></button>
                        </div>
                    </div> -->
                    <div class="d-flex justify-content-end gap-2">
                        <div class="form-group">
                            <!-- <label>Tanggal Bongkar <span class="text-danger">*</span></label> -->
                            <div class="input-groupicon" style="z-index: 101;">
                                <datepicker class="picker" :editable="true" :clearable="false"
                                    v-model="currentData.dfrom" />
                                <a class="addonset">
                                    <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                                </a>
                            </div>
                        </div>
                        <div class="form-group">
                            <!-- <label>Tanggal Bongkar <span class="text-danger">*</span></label> -->
                            <div class="input-groupicon" style="z-index: 101;">
                                <datepicker class="picker" :editable="true" :clearable="false"
                                    v-model="currentData.dto" />
                                <a class="addonset">
                                    <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                                </a>
                            </div>
                        </div>
                        <div class="form-group d-flex align-items-center rounded-2 bg-primary px-2" @click="index">
                            <vue-feather type="filter" class=""></vue-feather>
                        </div>




                    </div>
                    <pageheader title="Project" title1="" />
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Upcoming Project
                                </div>
                                <div>
                                    <vue3-autocounter class="counter fs-4" ref="counter" :startAmount="1385.0"
                                        :delay="3" :endAmount="parseInt(resultData.jumlah_project_pending)"
                                        :duration="2" :autoinit="true" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_project_pending)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Ongoing Project
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_project_berjalan)" :duration="2"
                                        :autoinit="true" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_project_berjalan)"
                                            :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Outstanding Project
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_project_outstanding)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_project_outstanding)"
                                            :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Finished Project
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_project_selesai)" :duration="2"
                                        :autoinit="true" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_project_selesai)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <div class="d-md-flex justify-content-between d-none ">
                        <pageheader title="Trip Reguler" title1="" />
                        <div class="d-flex justify-content-start w-50 ">
                            <pageheader title="Trip Khusus" title1="" class="ms-3" />
                        </div>
                    </div>

                    <pageheader title="Trip Reguler" title1="" class="d-block d-md-none" />
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Ongoing Trip
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_trip_reguler_berjalan)" :duration="2"
                                        :autoinit="true" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_trip_reguler_berjalan)"
                                            :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Outstanding Trip
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_trip_reguler_outstanding)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_trip_reguler_outstanding)"
                                            :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <pageheader title="Trip Khusus" title1="" class="d-block d-md-none" />

                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Upcoming Trip
                                </div>
                                
                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_trip_pending)" :duration="2"
                                        :autoinit="true" />
                                </div>
                                <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_trip_pending)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                                </div>

                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Ongoing Trip
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_trip_berjalan)" :duration="2"
                                        :autoinit="true" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_trip_berjalan)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Outstanding Trip
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_trip_outstanding)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_trip_outstanding)"
                                            :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Finished Trip
                                </div>
                                
                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_trip_selesai)" :duration="2"
                                        :autoinit="true" />
                                </div>
                                <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_trip_selesai)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                                </div>

                            </div>
                        </div>
                    </div> -->



                    <pageheader title="Lain-lain" title1="" />


                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    STNK Expiring
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_asset_stnk_expired)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_asset_stnk_expired)"
                                            :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    SIM Expiring
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_sim_expired)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_sim_expired)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> supir</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Uji KIR Expiring
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_asset_kir_expired)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_asset_kir_expired)"
                                            :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Pajak
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_maintenance)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_maintenance)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <div class="d-md-flex justify-content-between d-none ">
                        <pageheader title="Maintenance" title1="" />
                        <div class="d-flex justify-content-start w-50 ">
                            <pageheader title="SOS" title1="" class="ms-3" />
                        </div>
                    </div>

                    <pageheader title="Maintenance" title1="" class="d-block d-md-none" />
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Perbaikan
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_maintenance)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_maintenance)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Service
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_service)" :duration="2" :autoinit="true"
                                        :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_maintenance)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <pageheader title="SOS" title1="" class="d-block d-md-none" />
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <!-- <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div> -->
                            <div class="dash-widgetcontent">
                                <div class="">
                                    Kecelakaan
                                </div>

                                <div>
                                    <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.jumlah_kecelakaan)" :duration="2"
                                        :autoinit="true" :style="{ color: 'red' }" />
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                        class="text-success me-2"></vue-feather>
                                    <div class="text-success">
                                        <vue3-autocounter class="counter text-success" ref="counter"
                                            :startAmount="1385.0" :delay="3"
                                            :endAmount="parseFloat(resultData.persentasi_maintenance)" :duration="2"
                                            :autoinit="true" decimalSeparator=',' :decimals='1' />
                                        %
                                    </div>

                                    <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <!-- maps -->
                    <div class="col-lg-12 col-12 d-none">
                        <div class="maps">
                            <div class="card">
                                <div class="card-body">
                                    <h3>MAPS</h3>
                                    <div id="map" style="width: 100%; height: 500px;"></div>

                                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.8725877907514!2d106.85218437409532!3d-6.147809360261454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f53c263c914b%3A0x6723009746b9a883!2sArtha%20Skyline!5e0!3m2!1sen!2sid!4v1723092998588!5m2!1sen!2sid" width="1000" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->

                                    <!-- <GMapMap
                                    :center="{lat: 10, lng: 10}"
                                    :zoom="7"
                                    style="width: 100%; height: 400px;"
                                    >
                                    <GMapMarker
                                        :key="index"
                                        v-for="(m, index) in markers"
                                        :position="m.position"
                                        :clickable="true"
                                        :draggable="true"
                                    />
                                    </GMapMap> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card-title {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.card-header {
    padding-bottom: 0px !important;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right {

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: red; */
}

.dash-widget:hover {
    background-color: #043249;
    color: white !important;

}

.dash-widget:hover h5 {
    color: white !important;
}

.dash-widget:hover h6 {
    color: white !important;
}

.dash-widget:hover .dash-widgetcontent {
    color: white !important;
}

.dash-widget:hover .text-success {
    color: #47DE39 !important;
}

.dash-widget:hover .text-secondary {
    color: white !important;
}

.maps {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

#map {
    width: 100%;
    height: 500px;
    border: 1px solid grey;
    border-radius: 5px;
}

.counter {
    font-size: 24px !important;
}

.bg-primary {
    background-color: #2c3e50 !important;
    color: white !important;
}

.dash-widget {
    padding: 10px !important;
}

.dash-widget .dash-widgetcontent {
    margin-left: 10px !important;
}
</style>

<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, openInNewTab } from "@/assets/js/function";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
// import { nextTick } from 'vue';
// import MapView from '@/components/mapview.vue';

// Fix the marker icons issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    data() {
        return {


            companyList: [],
            config: "",
            api: "",
            showChart: false,

            //data
            currentData: {
                dfrom: "",
                dto: "",
            },

            resultData: {
                dashboard: {
                    total_deposit: 0,
                    total_customer: 0,
                    total_deposit
                        :
                        0,
                    total_invoices
                        :
                        0,
                    total_sales
                        :
                        0,
                    total_sales_fuqi
                        :
                        0,
                    jumlah_project_pending: 0,
                    persentasi_project_pending: 0,
                    jumlah_project_berjalan: 0,
                    persentasi_project_berjalan: 0,
                    jumlah_project_outstanding: 0,
                    persentasi_project_outstanding: 0,
                    jumlah_project_selesai: 0,
                    persentasi_project_selesai: 0,
                    jumlah_trip_pending: 0,
                    persentasi_trip_pending: 0,
                    jumlah_trip_berjalan: 0,
                    persentasi_trip_berjalan: 0,
                    jumlah_trip_outstanding: 0,
                    persentasi_trip_outstanding: 0,
                    jumlah_trip_selesai: 0,
                    persentasi_trip_selesai: 0,
                    jumlah_maintenance: 0,
                    persentasi_maintenance: 0,
                    jumlah_asset_stnk_expired: 0,
                    persentasi_asset_stnk_expired: 0,
                    jumlah_driver: 0,
                    persentasi_sim_expired: 0,
                    jumlah_asset_kir_expired: 0,
                    persentasi_asset_kir_expired: 0,
                },
                year_sales: [
                    {
                        year: "",
                        month: "Mar",
                        sales_amount: "11000000.00"
                    },

                ],

                markers: [
                    { position: { lat: 10, lng: 10 } },
                    { position: { lat: 11, lng: 11 } },
                ],
            },
            //token
            token: "",

            barchart: {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            barchart2: {

                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    indexAxis: "y",
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            barchart3: {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    indexAxis: "y",
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            // sbarAreaChart: {

            //     sbar: {
            //         chart: {
            //             id: "vuechart-example",
            //         },

            //         chart: {
            //             type: 'bar',
            //             height: 350,
            //             stacked: true,
            //             toolbar: {
            //                 show: false,
            //             }
            //         },
            //         plotOptions: {
            //             bar: {
            //                 horizontal: true,
            //             }
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         xaxis: {
            //             categories: [],
            //         }

            //     },

            //     series: [{
            //         name: "Jumlah",
            //         data: []
            //     }],

            // },
            // sbarAreaChart2: {

            //     sbar: {
            //         chart: {
            //             id: "vuechart-example",
            //         },

            //         chart: {
            //             type: 'bar',
            //             height: 350,
            //             stacked: true,
            //             toolbar: {
            //                 show: false,
            //             }
            //         },
            //         plotOptions: {
            //             bar: {
            //                 horizontal: true,
            //             }
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         xaxis: {
            //             categories: [],
            //         },
            //         yaxis: {
            //             reversed: false,
            //         }

            //     },

            //     series: [{
            //         name: "Total",
            //         colors: ['#043249'],
            //         data: []
            //     }],
            // },

            //tipe user 
            tipe: "",
            barchartrender: false,
            barchart2render: false,
            barchart3render: false,
            locations: [],
            newMap: [],

            statusMap: false,


        };
    },
    components: {
        Vue3ChartJs,
        // MapView
    },

    watch: {
        'currentData.dfrom': {
            handler(newValue) {
                this.statusMap = true;
            }
        },
    },
    methods: {

        exportExcel() {
            this.index();

            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                // Create a new Date object
                const date = new Date(value);

                // Use built-in methods to get year, month, and day
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                formData.append(key, formattedDate);
            });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_export';
            } else {
                this.api = base_url + 'dashboard_export';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        openInNewTab(response.data.data.file_report);
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        index() {
            ShowLoading();
            // this.barchart2render = false;
            // this.barchart3render = false;
            // this.barchartrender = false;
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                var formattedDate = value.toISOString().split('T')[0];

                formData.append(key, formattedDate);

            });

            // for (let [key, value] of formData.entries()) {

            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard';
            } else {
                this.api = base_url + 'dashboard';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {

                        this.resultData = response.data.data;


                        // console.log(response.data.data.golacakDashboard[0].deviceStatus.lat);
                        // this.lat = response.data.data.golacakDashboard[0].deviceStatus.lat;
                        // this.long = response.data.data.golacakDashboard[0].deviceStatus.lng;

                        this.locations = response.data.data.golacakDashboard;

                        if (this.statusMap == false) {
                            this.initializeMap();
                        }

                        // console.log(this.locations);




                        // console.log(this.long);
                        // console.log(this.lat);


                        // this.barchartrender = true;
                        // this.barchart.data.labels = [];
                        // this.barchart.data.datasets[0].data = [];

                        // this.barchart2render = true;
                        // this.barchart2.data.labels = [];
                        // this.barchart2.data.datasets[0].data = [];

                        // this.barchart3render = true;
                        // this.barchart3.data.labels = [];
                        // this.barchart3.data.datasets[0].data = [];

                        // response.data.data.year_sales.forEach((item) => {
                        //     this.barchart.data.labels.push(item.month)
                        //     this.barchart.data.datasets[0].data.push(parseInt(item.sales_amount))
                        // });



                        // response.data.data.best_seller.forEach((item) => {
                        //     this.barchart2.data.labels.push(item.item_name)
                        //     this.barchart2.data.datasets[0].data.push(parseInt(item.item_total))
                        // });


                        // response.data.data.payment.forEach((item) => {
                        //     this.barchart3.data.labels.push(item.payment_method)
                        //     this.barchart3.data.datasets[0].data.push(parseInt(item.payment_amount))
                        // });

                        CloseLoading();
                    }



                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                    // console.log(response.data)

                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },

        initializeMap() {
            // console.log(L)

            // Initialize the map
            const map = L.map('map');

            this.locations.forEach(lokasi => {
                // console.log(lokasi.deviceStatus.lat, lokasi.deviceStatus.lng);
                map.setView([lokasi.deviceStatus.lat, lokasi.deviceStatus.lng], 13);
                const marker = L.marker([lokasi.deviceStatus.lat, lokasi.deviceStatus.lng]).addTo(map)
                marker.bindPopup(lokasi.popuptext || `Lat: ${lokasi.deviceStatus.lat}<br>Long: ${lokasi.deviceStatus.lng}`);

            });

            // Add the tile layer to the map
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(map);

            // const checkLocation = this.locations[0];
            // const checkLocation = this.locations;

            // console.log(checkLocation);

            // const latitude = 3.569751;    
            // const longitude = 98.686671;


            // const latitude = checkLocation.deviceStatus.lat; 
            // const longitude = checkLocation.deviceStatus.lng;
            // const vinNum = checkLocation.vin
            // const licenseNum = checkLocation.licenseNumber
            // // console.log(checkLocation.deviceStatus.lat)

            // // Function to set map view and add marker
            // const setMapView = (latitude, longitude, popupText) => {
            //     map.setView([latitude, longitude], 13);
            //     L.marker([latitude, longitude]).addTo(map).bindPopup(popupText).openPopup();
            // };

            // setMapView(latitude, longitude, `Latitude: ${latitude}<br>Longitude: ${longitude}<br>Vin Number: ${vinNum}<br>License Number: ${licenseNum}`);




            // const marker = L.marker([latitude, longitude]).addTo(map);
            // marker.bindPopup(`Latitude: ${latitude}<br>Longitude: ${longitude}<br>Vin Number: ${vinNum}<br>License Number: ${licenseNum}`);

            // this.locations.forEach(location => {
            //     const marker = L.marker([location.latitude, location.longitude]).addTo(map);
            //     marker.bindPopup(location.popupText || `Latitude: ${location.deviceStatus.lat}<br>Longitude: ${location.deviceStatus.lng}`);
            // });






        },




    },



    created() {
        ShowLoading();
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
        let currentDate = new Date();
        // First day of the month
        let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        // Last day of the month
        let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        // Assigning to data object
        this.currentData.dfrom = firstDayOfMonth;
        this.currentData.dto = lastDayOfMonth;
        // this.index();
        this.tipe = localStorage.getItem('tipe_user');

        this.index();

        // this.dashboard_notif();
        // this.dashboard_index();
    }


};
</script>
